import { ErrorIcon, SettingsIcon } from '@onbeam/icons';
import { css } from '@onbeam/styled-system/css';
import { text, vstack } from '@onbeam/styled-system/patterns';
import {
  Button,
  Icon,
  Modal,
  Popover,
  TransactionSlippageSettings,
  TransactionDeadlineSettings,
  TransactionInterfaceSettings,
} from '@onbeam/ui';
import { ChangeEvent, PropsWithChildren, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AutoColumn, ColumnCenter } from '../../components/Column';
import {
  useSetSettingsMenu,
  useSettingsMenuOpen,
  useToggleSettingsMenu,
} from '../../state/application/hooks';
import {
  useExpertModeManager,
  useUserDeadline,
  useUserSlippageTolerance,
} from '../../state/user/hooks';

const EmojiWrapper = ({ children, ...props }: PropsWithChildren) => (
  <div
    className={css({
      position: 'absolute',
      bottom: '[-6px]',
      right: '0',
      fontSize: '[14px]',
    })}
    {...props}
  >
    {children}
  </div>
);

export const Settings = () => {
  const node = useRef<HTMLDivElement>(null);
  const isOpen = useSettingsMenuOpen();
  const setSettingsMenu = useSetSettingsMenu();
  const toggle = useToggleSettingsMenu();
  const { t } = useTranslation();
  const [userSlippageTolerance, setUserSlippageTolerance] =
    useUserSlippageTolerance();

  const [deadline, setDeadline] = useUserDeadline();

  const [expertMode, toggleExpertMode] = useExpertModeManager();

  // show confirmation view before turning on
  const [showConfirmation, setShowConfirmation] = useState(false);

  return (
    <div ref={node}>
      <Modal
        title={t('areYouSure')}
        description=""
        open={showConfirmation}
        onOpenChange={setShowConfirmation}
      >
        <AutoColumn $gap="lg">
          <ColumnCenter>
            <Icon size={48}>
              <ErrorIcon />
            </Icon>
          </ColumnCenter>
          <p>
            <span className={text({ color: 'red.400' })}>important: </span>
            {t('expertModeTips1')}
          </p>
          <p>{t('expertModeTips2')}</p>
          <Button
            size="lg"
            id="confirm-expert-mode"
            onClick={() => {
              if (
                window.prompt(
                  `Please type the word "confirm" to enable expert mode.`,
                ) === 'confirm'
              ) {
                toggleExpertMode();
                setShowConfirmation(false);
              }
            }}
          >
            {t('turnOnExpertMode')}
          </Button>
        </AutoColumn>
      </Modal>

      <div>
        <Popover
          title="transaction settings"
          size="md"
          align="end"
          open={isOpen}
          onOpenChange={setSettingsMenu}
          trigger={
            <Button size="sm" onClick={toggle} id="open-settings-dialog-button">
              <div className={css({ hideBelow: 'sm' })}>
                settings
                {expertMode && (
                  <EmojiWrapper>
                    <span role="img" aria-label="wizard-icon">
                      🧙
                    </span>
                  </EmojiWrapper>
                )}
              </div>
              <Icon size={18} className={css({ hideFrom: 'sm' })}>
                <SettingsIcon />
              </Icon>
            </Button>
          }
        >
          <div className={vstack({ align: 'center', gap: '4' })}>
            <TransactionSlippageSettings
              value={userSlippageTolerance}
              onChange={setUserSlippageTolerance}
            />
            <TransactionDeadlineSettings
              value={deadline}
              onChange={setDeadline}
            />
            <TransactionInterfaceSettings
              checked={expertMode}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                if (e.target.checked) {
                  toggle();
                  setShowConfirmation(true);
                } else {
                  toggleExpertMode();
                  setShowConfirmation(false);
                }
              }}
              id="toggle-expert-mode-button"
            />
          </div>
        </Popover>
      </div>
    </div>
  );
};
