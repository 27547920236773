import { footerStyle } from './Footer.styles';
import { link } from '@onbeam/styled-system/patterns';
import { useCookieConsentStore } from '@onbeam/utils';

export const Footer = () => {
  const open = useCookieConsentStore((store) => store.openConsentModal);

  return (
    <footer className={footerStyle}>
      <a
        href="https://onbeam.com/terms"
        rel="noopener noreferrer"
        target="_blank"
        className={link({ style: 'sm', color: 'mono.100' })}
      >
        terms of service
      </a>
      <a
        href="https://onbeam.com/privacy-policy"
        rel="noopener noreferrer"
        target="_blank"
        className={link({ style: 'sm', color: 'mono.100' })}
      >
        privacy policy
      </a>
      <a
        href="https://support.onbeam.com/hc/en-us/categories/14553016517533-Beam-Swap"
        target="_blank"
        rel="noopener noreferrer"
        className={link({ style: 'sm', color: 'mono.100' })}
      >
        support
      </a>
      <button
        type="button"
        className={link({ style: 'sm', color: 'mono.100' })}
        onClick={open}
      >
        cookies
      </button>
    </footer>
  );
};
