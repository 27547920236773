import type { NavigationItem } from '@onbeam/ui';

export const navItems: NavigationItem[] = [
  { href: '/swap', label: 'swap' },
  { href: '/pool', label: 'pool' },
  {
    href: 'https://bridge.onbeam.com',
    label: 'bridge',
    isExternal: true,
  },
];
